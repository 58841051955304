var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "domain-ngo-modal",
      attrs: {
        id: "domain-ngo-modal",
        "hide-footer": "",
        size: "lg",
        centered: "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("div", { staticClass: "ml-2 mr-auto my-auto" }, [
                _vm._v("NGO/ONG Registration Policy"),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _vm._v(
            "\n    To select this domain, you must first accept the NGO/ONG "
          ),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://pir.org/our-impact/ngo-ong-policy/",
              },
            },
            [_vm._v("Registration Policy")]
          ),
          _vm._v(".\n    "),
          _c("br"),
          _c(
            "b-checkbox",
            {
              ref: "agreement-checkbox",
              staticClass: "mb-3 mt-3",
              on: {
                change: function ($event) {
                  return _vm.agreementChanged($event)
                },
              },
              model: {
                value: _vm.agreedToRegistrationPolicy,
                callback: function ($$v) {
                  _vm.agreedToRegistrationPolicy = $$v
                },
                expression: "agreedToRegistrationPolicy",
              },
            },
            [_vm._v("\n      I have Read and Agree\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mb-2 mr-3",
              attrs: { variant: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.decline()
                },
              },
            },
            [_vm._v("\n      Decline\n    ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mb-2",
              attrs: {
                variant: "primary",
                disabled: !_vm.agreedToRegistrationPolicy,
              },
              on: {
                click: function ($event) {
                  return _vm.agreed()
                },
              },
            },
            [_vm._v("\n      Agree\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }