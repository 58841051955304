<template>
  <b-modal
    id="domain-ngo-modal"
    ref="domain-ngo-modal"
    hide-footer
    size="lg"
    centered
  >
    <template #modal-header>
      <div class="ml-2 mr-auto my-auto">NGO/ONG Registration Policy</div>
    </template>
    <div class="text-center">
      To select this domain, you must first accept the NGO/ONG <a target="_blank" href="https://pir.org/our-impact/ngo-ong-policy/">Registration Policy</a>.
      <br>
      <b-checkbox
        ref="agreement-checkbox"
        v-model="agreedToRegistrationPolicy"
        class="mb-3 mt-3"
        @change="agreementChanged($event)"
      >
        I have Read and Agree
      </b-checkbox>
      <b-button
        class="mb-2 mr-3"
        variant="secondary"
        @click="decline()"
      >
        Decline
      </b-button>
      <b-button
        class="mb-2"
        variant="primary"
        :disabled="!agreedToRegistrationPolicy"
        @click="agreed()"
      >
        Agree
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'DomainNgoModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      agreedToRegistrationPolicy: false,
      selectedItem: '',
    }
  },
  methods: {
    agreementChanged (agreed) {
      this.agreedToRegistrationPolicy = agreed
    },
    agreed() {
      this.$emit('agreed', this.selectedItem)
      this.$bvModal.hide('domain-ngo-modal')
    },
    decline() {
      this.$bvModal.hide('domain-ngo-modal')
    },
    show(item) {
      this.selectedItem = item
      this.agreedToRegistrationPolicy = false
      this.$bvModal.show('domain-ngo-modal')
    },
  },
}

</script>
